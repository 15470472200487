<template>
  <div>

    <h1 class="heading">Start Partners</h1>
    <div style="border-radius:6px;border:1px solid #ccc;display: flex;justify-content: space-evenly;align-items: center;margin-top: 15px;width:80%;margin-left:10%;">
      <div @click="openLink(data.link)" :style="`cursor:pointer;border-right:${(index!==startData.length-1)?'1px solid #ccc':'none'}`" style="flex:1;display: flex;border-right:1px solid #ccc;flex-direction: column;align-items: center;justify-content: center;" v-for="(data,index) in startData" :key="data.name">
        <div style="height: 48px;display: flex;align-items: center;justify-content: center;">
         {{data.name}}
        </div>
        <div style="height: 48px;display: flex;align-items: center;justify-content: center;font-size: 30px">
          {{data.value}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AgencyService from "../services/agency.service";
  import titleMixin from "../mixins/titleMixin";
  export default {
    mixins:[titleMixin],
    title(){
      return "Partners Start"
    },
    name: "StartPartners",
    data(){
      return{
        startData:[
          {
            name:"Users",
            value:0,
            link:"/partners/users"
          },
          {
            name:"Hotels",
            value:0,
            link:"/partners/hotels"
          },
          {
            name:"Hotels on standart",
            value:0,
            link:"/partners/hotels"
          },
          {
            name:"Hotels on profi",
            value:0,
            link:"/partners/hotels"
          },
          {
            name:"Hotels on free",
            value:0,
            link:"/partners/hotels"
          },
        ],
        users:[],
        hotels:[]
      }
    },
    methods:{
      openLink(link){
        this.$router.push(link);
      }
    },
    async mounted() {
      this.hotels = await AgencyService.getHotels();
      this.users = await AgencyService.getUsers();
      this.startData[0].value = this.users.length;
      this.startData[1].value = this.hotels.length;
      this.startData[2].value = this.hotels.filter(x => x.tariff_id === 2).length;
      this.startData[3].value = this.hotels.filter(x => x.tariff_id === 3).length;
      this.startData[4].value = this.hotels.filter(x => x.tariff_id === 1).length;
    }
  }
</script>

<style scoped>

</style>
